import { useFormState } from '@graphcommerce/ecommerce-ui'
import { ApolloCartErrorSnackbar } from '@graphcommerce/magento-cart'
import { useFormAddProductsToCart } from '@graphcommerce/magento-product'
import { toUserErrors } from '@graphcommerce/magento-product/components/AddProductsToCart/toUserErrors'
import {
  Button,
  ErrorSnackbar,
  ErrorSnackbarProps,
  filterNonNullableKeys,
  iconChevronRight,
  IconSvg,
  MessageSnackbar,
  MessageSnackbarProps,
} from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Snackbar } from '@mui/material'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import IconCross from '../icons/IconCross'

export type AddProductsToCartSnackbarProps = {
  errorSnackbar?: Omit<ErrorSnackbarProps, 'open'>
  successSnackbar?: Omit<MessageSnackbarProps, 'action'>
}

export function AddProductsToCartSnackbar(props: AddProductsToCartSnackbarProps) {
  const { errorSnackbar, successSnackbar } = props
  const { error, data, redirect, control } = useFormAddProductsToCart()
  const formState = useFormState({ control })
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)

  const userErrors = toUserErrors(data)

  useEffect(() => {
    if (!formState.isSubmitting ) {
      if (formState.isSubmitSuccessful && !error?.message && !userErrors.length && !redirect)
        setShowSuccess(true)
      else if (userErrors.length > 0 && !redirect) {
        setShowError(true)
      }
    }
  }, [formState.isSubmitting])

  const items = filterNonNullableKeys(data?.addProductsToCart?.cart.items)

  const showErrorSnackbar = userErrors.length > 0

  const handleHide = () => {
    setShowSuccess(false)
    successSnackbar?.onClose && successSnackbar?.onClose()
  }
  const handleHideErr = () => {
    setShowError(false)
    // successSnackbar?.onClose && successSnackbar?.onClose();
  }
  return (
    <>
      {error && <ApolloCartErrorSnackbar error={error} />}

      {/* {showErrorSnackbar && ( */}
      <Snackbar
        open={showError}
        autoHideDuration={3000}
        onClose={handleHideErr}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <div className='flex max-w-[350px] justify-between items-center box-border border-error-500 rounded-sm border border-solid bg-white gap-3 p-3'>
          <p>
          {userErrors?.map((e) => e?.message).join(', ')}
          </p>
          <IconCross
            width={24}
            height={24}
            className='text-light-400 cursor-pointer'
            onClick={handleHideErr}
          />
        </div>
      </Snackbar>

      {/* )} */}

      {/* {showSuccess && ( */}
      <Snackbar
        style={{ zIndex: 999999,marginTop:'30px' }}
        open={showSuccess}
        autoHideDuration={3000}
        onClose={handleHide}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <div className='flex relative max-w-[350px] justify-between items-center box-border  rounded-sm border border-solid bg-white gap-3 p-3'>
          <Image
            width={60}
            height={60}
            alt=''
            src={items[items.length - 1]?.product.thumbnail?.url ?? ''}
            className='border border-solid w-16 h-16'
          />
          <p className='text-base font-medium text-light-400'>{`${items[items.length - 1]?.product
            .name} ${i18n._('Added to your shopping cart.')}`}</p>

          <IconCross
            width={24}
            height={24}
            className='text-light-400 absolute top-1 left-1 cursor-pointer'
            onClick={handleHide}
          />
        </div>
      </Snackbar>

      {/* )} */}
    </>
  )
}
