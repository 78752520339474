/* eslint-disable no-nested-ternary */
import {
  Controller,
  ControllerProps,
  Control,
  FieldError,
  useFormContext,
  FieldValues,
  UseControllerProps,
} from '@graphcommerce/react-hook-form'
import { i18n } from '@lingui/core'
import { TextField, TextFieldProps } from '@mui/material'
import { useEffect } from 'react'

export type TextFieldElementProps<T extends FieldValues = FieldValues> = Omit<
  TextFieldProps,
  'name' | 'defaultValue'
> & {
  validation?: UseControllerProps<T>['rules']
  parseError?: (error: FieldError) => string
  persianField?: boolean
  nationalCode?: boolean
  numberField?: boolean
} & UseControllerProps<T>
const validateIranianNationalCode = (code) => {
  if (!code || code == '') {
    return true
  }
  if (!/^\d{10}$/.test(code)) {
    return false
  }

  const digits = code.split('').map(Number)
  const checkDigit = digits.pop()
  const sum = digits.reduce((acc, digit, index) => acc + digit * (10 - index), 0)
  const remainder = sum % 11

  return (
    (remainder < 2 && checkDigit === remainder) || (remainder >= 2 && checkDigit === 11 - remainder)
  )
}

export function TextFieldElement<TFieldValues extends FieldValues>({
  validation = {},
  parseError,
  type,
  required,
  name,
  control,
  defaultValue,
  persianField,
  numberField,
  nationalCode,
  label,
  ...rest
}: TextFieldElementProps<TFieldValues>): JSX.Element {
  if (type === 'email' && !validation.pattern) {
    validation.pattern = {
      // eslint-disable-next-line no-useless-escape
      value:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: i18n._('Please enter a valid email address'),
    }
  }

  if (type === 'tel' && !validation.pattern) {
    validation.pattern = {
      // eslint-disable-next-line no-useless-escape
      value: /^(\+98|0)?9\d{9}$/,
      message: i18n._('Please enter a valid mobile number.'),
    }
  }

  if (type === 'log' && !validation.pattern) {
    validation.pattern = {
      value: /(^(\+98|0)?9\d{9}$)|(^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/,
      message: i18n._('Please enter a valid mobile number or email address.'),
    }
  }

  if (type === 'otp' && !validation.pattern) {
    validation.min = {
      // eslint-disable-next-line no-useless-escape
      value: 5,
      message: i18n._('The code does not have 5 digits.'),
    }
  }

  if (type === 'post' && !validation.pattern) {
    validation.pattern = {
      // eslint-disable-next-line no-useless-escape
      value: /^\d{10}$/,
      message: i18n._('Please enter a valid postcode.'),
    }
  }

  const {
    formState: { errors, dirtyFields },
    setError,
    trigger,
    setValue,
    clearErrors,
  } = useFormContext()

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue)
    }
  }, [defaultValue])
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...validation,
        ...(required ? { required: i18n._('Items with an asterisk are required.') } : null),
        ...(persianField
          ? {
              pattern: {
                value: /^[\u0600-\u06FF\s_-]+$/,
                message: i18n._('Only Persian letters are allowed'),
              },
            }
          : null),
        ...(numberField
          ? {
              pattern: {
                value: /^(0|[1-9]\d*)(\.\d+)?$/,
                message: i18n._('Only Persian letters are allowed'),
              },
            }
          : null),
        ...(nationalCode
          ? {
              validate: {
                validChecksum: (value) =>
                  validateIranianNationalCode(value) || i18n._('The national code is not valid'),
              },
            }
          : null),
      }}
      defaultValue={defaultValue}
      render={({
        field: { value, onChange, onBlur, ref },
        fieldState: { error, isDirty },
        formState: { errors },
      }) => (
        <TextField
          {...rest}
          name={name}
          ref={ref}
          label={`${label}${required ? '*' : ''}`}
          value={value ?? ''}
          onChange={(ev) => {
            if (type === 'number') {
              onChange(ev.target.value ? Number(ev.target.value) : ev.target.value)
            } else if (type === 'otp') {
              onChange(ev.target.value.slice(0, 5))
            } else {
              onChange(ev.target.value)
            }
          }}
          onBlur={(e) => {
            if (type === 'tel') {
              trigger(name)
            }

            if (persianField) {
              !e.target.value.match(/^[\u0600-\u06FF\s]+$/)
                ? setError(name, {
                    type: 'custom',
                    message: i18n._('Only Persian letters are allowed'),
                  })
                : clearErrors(name)
            }
            onBlur()
          }}
          type={type === 'otp' ? 'tel' : type}
          error={!!error}
          helperText={error ? error.message : ''}
          dir='rtl'
          inputRef={ref}
          InputProps={{ classes: { input: '!text-sm', hiddenLabel: 'text-2xl' } }}
          FormHelperTextProps={{ classes: '!text-sm', className: '!text-xs text-right' }}
          InputLabelProps={{ classes: 'text-sm', dir: 'rtl' }}
        />
      )}
    />
  )
}
