import { useApolloClient, useQuery } from '@graphcommerce/graphql'
import { CustomerTokenDocument } from '@graphcommerce/magento-customer'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { ModalContext } from '../../context/context'
import { useLoading } from '../../context/loadingContext'
import { customerDocument } from '../../graphql/login/currentCustomerData.gql'
import { useMergeCompareList } from './useMergeCompareList'

const useLogin = () => {
  const mergeCompareList = useMergeCompareList()

  const client = useApolloClient()

  const { refetch } = useQuery(customerDocument, { skip: true })

  const router = useRouter()

  const { setLoading } = useLoading()

  // const { refetch: refetchCart } = useCartQuery(getCartDocument, { fetchPolicy: 'network-only' })

  const useModal = useContext(ModalContext)
  const handleLogin = async (
    token: string,
    onComplete: null | (() => void) = null,
    redirect: boolean = true,
    stayAtPage: boolean = false,
  ) => {
    client.writeQuery({
      query: CustomerTokenDocument,
      data: {
        customerToken: {
          __typename: 'CustomerToken',
          token,
          createdAt: new Date().toUTCString(),
          valid: true,
        },
      },
      broadcast: true,
    })

    await refetch()
      .then(({ data: { customer } }) => {
        client.writeQuery({
          query: customerDocument,
          data: {
            customer,
          },
          broadcast: true,
        })
      })
      .then(async () => {
        // await refetchCart()I
        await mergeCompareList()
        if (stayAtPage) {
          await router.push(router.asPath)
        } else if (redirect) {
          await router.push('/')
        }
        if (useModal.close) useModal.close()
        if (onComplete) onComplete()
        setLoading(false)
        router.reload()
      })
  }

  return { handleLogin }
}

export default useLogin
